@font-face {
  font-family: "CookieRun Bold";
  src: url(Font/CookieRun_Bold.ttf) format("truetype");
}
@font-face {
  font-family: "CookieRun Regular";
  src: url(Font/CookieRun_Regular.ttf) format("truetype");
}

@font-face {
  font-family: "CookieRun Bold";
  src: url(Font/CookieRun_Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Jalnan";
  src: url(Font/Jalnan.ttf) format("truetype");
}
@font-face {
  font-family: "SCDream4";
  src: url(Font/SCDream4.otf) format("opentype");
}

@font-face {
  font-family: "SCDream2";
  src: url(Font/SCDream2.otf) format("opentype");
}

@font-face {
  font-family: "SCDream7";
  src: url(Font/SCDream7.otf) format("opentype");
}

@font-face {
  font-family: "SCDream8";
  src: url(Font/SCDream8.otf) format("opentype");
}

@font-face {
  font-family: "SCDream9";
  src: url(Font/SCDream9.otf) format("opentype");
}

@font-face {
  font-family: "SB_B";
  src: url(Font/SB_B.ttf) format("truetype");
}

@font-face {
  font-family: "SB_L";
  src: url(Font/SB_L.ttf) format("truetype");
}

@font-face {
  font-family: "SB_M";
  src: url(Font/SB_M.ttf) format("truetype");
}
